import { Loader2 } from "lucide-react";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Alert, AlertDescription } from "@/components/ui/alert";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { EventCreationResponse } from "@/types/api";
import { useAuth } from "@/components/AuthProvider";
import { convertToUTC, convertFromUTC } from "@/lib/time_utils";
import EventDetails from "@/components/EventDetails";
import { EventFormData } from "@/components/EventDetails";
import { Event } from "@/types/api";
import { EventDeletionResult } from "~/functions/api/delete-event";

const EventEditPage = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const { eventId } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);

    const [formData, setFormData] = useState<EventFormData>({
        eventId: eventId,
        title: "",
        venue: "",
        startDateTime: "",
        endDateTime: "",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        eventUrl: "",
        description: "",
        userId: user?.id,
        createdBy: user?.id ?? "",
    });

    useEffect(() => {
        const fetchEvent = async () => {
            try {
                const response = await fetch(`/api/get-event/${eventId}`);
                if (!response.ok) {
                    throw new Error("Failed to fetch event details");
                }

                const eventResponse = await response.json() as { event: Event };
                const eventData = eventResponse.event;
                console.log("timezone ", eventData.timezone, eventData.start_datetime)
                // Convert UTC times to local timezone for form
                const localStartDateTime = convertFromUTC(
                    eventData.start_datetime,
                    eventData.timezone
                );
                const localEndDateTime = eventData.end_datetime
                    ? convertFromUTC(eventData.end_datetime, eventData.timezone)
                    : "";
                setFormData({
                    eventId: eventData.id,
                    title: eventData.title,
                    venue: eventData.venue,
                    startDateTime: localStartDateTime,
                    endDateTime: localEndDateTime,
                    timezone: eventData.timezone,
                    eventUrl: eventData.event_url || "",
                    description: eventData.description || "",
                    createdBy: user?.id ?? "",
                    userId: user?.id,
                });
            } catch (err) {
                setError(err instanceof Error ? err.message : "Failed to load event");
                navigate(`/${user?.username}`);
            } finally {
                setIsFetching(false);
            }
        };

        if (eventId) {
            fetchEvent();
        }
    }, [eventId, navigate]);

    const handleDelete = async () => {
        setIsDeleting(true);
        setError(null);

        try {
            const response = await fetch('/api/delete-event', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    eventId: eventId,
                    userId: user?.id,
                }),
            });

            if (!response.ok) {
                const data = await response.json() as EventDeletionResult;
                throw new Error(data.message || "Failed to delete event");
            }

            setDeleteSuccess(true);
            setTimeout(() => {
                navigate(`/${user?.username}`);
            }, 2000);
        } catch (err) {
            setError(err instanceof Error ? err.message : "Failed to delete event");
        } finally {
            setIsDeleting(false);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);

        try {
            const formDataToSend = new FormData();

            // Convert times to UTC before sending
            const formattedData = {
                ...formData,
                startDateTime: convertToUTC(formData.startDateTime, formData.timezone),
                endDateTime: formData.endDateTime
                    ? convertToUTC(formData.endDateTime, formData.timezone)
                    : '',
            };

            Object.entries(formattedData).forEach(([key, value]) => {
                if (value) formDataToSend.append(key, value);
            });

            const response = await fetch('/api/edit-event', {
                method: 'PUT',
                body: formDataToSend,
            });

            if (!response.ok) {
                const data: EventCreationResponse = await response.json();
                throw new Error(data.message || "Failed to update event");
            }

            setSubmitSuccess(true);
            setTimeout(() => {
                navigate(`/${user?.username}`);
            }, 2000);
        } catch (err) {
            setError(err instanceof Error ? err.message : "Something went wrong");
        } finally {
            setIsLoading(false);
        }
    };

    if (isFetching) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="flex items-center space-x-2">
                    <Loader2 className="w-6 h-6 animate-spin" />
                    <span>Loading event details...</span>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white flex flex-col items-center p-4">
            <div className="w-full max-w-2xl py-8">
                <Card className="border-0 shadow-lg">
                    <CardHeader className="text-center space-y-2">
                        <CardTitle className="text-3xl font-bold bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
                            Edit Event
                        </CardTitle>
                        <CardDescription className="text-gray-600 text-lg">
                            Update your event details
                        </CardDescription>
                    </CardHeader>
                    <CardContent>
                        <form onSubmit={handleSubmit} className="space-y-6">
                            <EventDetails
                                formData={formData}
                                setFormData={setFormData}
                                error={error}
                            />

                            {submitSuccess && (
                                <Alert className="mt-4 bg-green-50 text-green-700 border-green-200">
                                    <AlertDescription>
                                        Event updated successfully! Redirecting to your profile...
                                        ✨
                                    </AlertDescription>
                                </Alert>
                            )}
                            {deleteSuccess && (
                                <Alert className="mt-4 bg-orange-50 text-red-700 border-orange-200">
                                    <AlertDescription>
                                        Event deleted. Redirecting to your profile...
                                    </AlertDescription>
                                </Alert>
                            )}

                            <div className="flex space-x-4">
                                <AlertDialog>
                                    <AlertDialogTrigger asChild>
                                        <Button
                                            type="button"
                                            variant="outline"
                                            className="flex-1 h-12 bg-gradient-to-r from-red-600 to-red-600 hover:from-red-700 hover:to-red-700 text-white transition-all duration-200 transform hover:scale-[1.02]"
                                            disabled={isDeleting}
                                        >
                                            {isDeleting ? (
                                                <div className="flex items-center justify-center space-x-2">
                                                    <Loader2 className="w-5 h-5 animate-spin" />
                                                    <span>Deleting...</span>
                                                </div>
                                            ) : (
                                                "Delete Event"
                                            )}
                                        </Button>
                                    </AlertDialogTrigger>
                                    <AlertDialogContent>
                                        <AlertDialogHeader>
                                            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                                            <AlertDialogDescription>
                                                This action cannot be undone. This will permanently delete your event.
                                            </AlertDialogDescription>
                                        </AlertDialogHeader>
                                        <AlertDialogFooter>
                                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                                            <AlertDialogAction
                                                onClick={handleDelete}
                                                className="bg-red-600 hover:bg-red-700"
                                            >
                                                Delete
                                            </AlertDialogAction>
                                        </AlertDialogFooter>
                                    </AlertDialogContent>
                                </AlertDialog>
                                <Button
                                    type="button"
                                    variant="outline"
                                    className="flex-1 h-12"
                                    onClick={() => navigate(-1)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    className="flex-1 h-12 bg-gradient-to-r from-purple-600 to-blue-600 hover:from-purple-700 hover:to-blue-700 text-white transition-all duration-200 transform hover:scale-[1.02]"
                                    disabled={
                                        isLoading ||
                                        !formData.title ||
                                        !formData.venue ||
                                        !formData.startDateTime ||
                                        !formData.timezone ||
                                        submitSuccess
                                    }
                                >
                                    {isLoading ? (
                                        <div className="flex items-center justify-center space-x-2">
                                            <Loader2 className="w-5 h-5 animate-spin" />
                                            <span>Updating Event...</span>
                                        </div>
                                    ) : (
                                        "Update Event"
                                    )}
                                </Button>
                            </div>
                        </form>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default EventEditPage;