import React from 'react';
import { ExternalLink } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip";

interface EventLinkProps {
    url: string;
}

const EventLink: React.FC<EventLinkProps> = ({ url }) => {
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <div className="relative">
                        {/* Animated light effect container */}
                        <div className="absolute inset-0 rounded-full bg-violet-300 opacity-0 animate-pulse-glow"></div>

                        <Button
                            variant="ghost"
                            className="relative rounded-full bg-violet-100 hover:bg-violet-200 transition-colors
                                      group-hover:rotate-1 duration-300 px-3 py-1.5 h-auto text-sm text-violet-600
                                      font-medium justify-start ring-2 ring-violet-200 ring-offset-1 ring-offset-white
                                      hover:ring-violet-300 focus:ring-violet-400 focus:outline-none"
                            onClick={() => window.open(url, '_blank', 'noopener noreferrer')}
                        >
                            <ExternalLink className="h-4 w-4 text-violet-600 mr-1.5 inline-block" />
                            Visit link

                            {/* Inner glow effect */}
                            <span className="absolute inset-0 rounded-full bg-gradient-to-r from-violet-200 to-transparent
                                            opacity-0 hover:opacity-30 blur-sm transition-opacity"></span>
                        </Button>
                    </div>
                </TooltipTrigger>
                <TooltipContent>
                    <p>Go to event</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};

export default EventLink;