import { Calendar, Clock, Link as LinkIcon, MapPin, Globe } from "lucide-react";
import React from "react";

import { Alert, AlertDescription } from "@/components/ui/alert";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import URLInput from "@/components/URLInput";
import { calculateEndTime, getDefaultStartTime } from "@/lib/time_utils";

export interface EventFormData {
    eventId?: string;
    title: string;
    venue: string;
    startDateTime: string;
    endDateTime: string;
    timezone: string;
    eventUrl: string;
    description: string;
    userId?: string;
    createdBy?: string;
}

interface EventDetailsProps {
    formData: EventFormData;
    setFormData: React.Dispatch<React.SetStateAction<EventFormData>>;
    error?: string | null;
}

// EventDetails component holds users create and edit events.
const EventDetails: React.FC<EventDetailsProps> = ({
    formData,
    setFormData,
    error
}) => {
    const handleStartDateTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newStartDateTime = e.target.value;
        setFormData((prev: EventFormData) => ({
            ...prev,
            startDateTime: newStartDateTime,
            endDateTime: !prev.endDateTime || new Date(prev.endDateTime) <= new Date(newStartDateTime)
                ? calculateEndTime(newStartDateTime)
                : prev.endDateTime
        }));
    };

    return (
        <div className="space-y-6">
            {/* Title */}
            <div className="space-y-2">
                <Label htmlFor="title" className="text-sm font-medium">
                    Event Title
                </Label>
                <Input
                    id="title"
                    value={formData.title}
                    onChange={(e) =>
                        setFormData((prev: EventFormData) => ({
                            ...prev,
                            title: e.target.value,
                        }))
                    }
                    required
                    placeholder="Give your event a catchy title"
                    className="h-12 text-lg px-4 border-gray-200 focus:border-purple-500 focus:ring-purple-500"
                />
            </div>

            {/* Venue */}
            <div className="space-y-2">
                <Label htmlFor="venue" className="text-sm font-medium">
                    Venue
                </Label>
                <div className="relative">
                    <MapPin className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                    <Input
                        id="venue"
                        value={formData.venue}
                        onChange={(e) =>
                            setFormData((prev: EventFormData) => ({
                                ...prev,
                                venue: e.target.value,
                            }))
                        }
                        required
                        placeholder="Where is your event taking place?"
                        className="h-12 pl-12 border-gray-200 focus:border-purple-500 focus:ring-purple-500"
                    />
                </div>
            </div>

            {/* Date/Time and Timezone */}
            <div className="space-y-4">
                <Label className="text-sm font-medium">Date & Time</Label>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {/* Start DateTime */}
                    <div className="space-y-2">
                        <Label htmlFor="startDateTime" className="text-xs text-gray-500">
                            Start
                        </Label>
                        <div className="relative">
                            <Calendar className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                            <Input
                                id="startDateTime"
                                type="datetime-local"
                                value={formData.startDateTime}
                                onChange={handleStartDateTimeChange}
                                min={getDefaultStartTime()}
                                required
                                className="h-12 pl-12 border-gray-200 focus:border-purple-500 focus:ring-purple-500"
                            />
                        </div>
                    </div>

                    {/* End DateTime */}
                    <div className="space-y-2">
                        <Label htmlFor="endDateTime" className="text-xs text-gray-500">
                            End (Optional)
                        </Label>
                        <div className="relative">
                            <Clock className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                            <Input
                                id="endDateTime"
                                type="datetime-local"
                                value={formData.endDateTime}
                                onChange={(e) =>
                                    setFormData((prev: EventFormData) => ({
                                        ...prev,
                                        endDateTime: e.target.value,
                                    }))
                                }
                                min={formData.startDateTime}
                                className="h-12 pl-12 border-gray-200 focus:border-purple-500 focus:ring-purple-500"
                            />
                        </div>
                    </div>
                </div>

                {/* Timezone */}
                <div className="space-y-2">
                    <Label htmlFor="timezone" className="text-sm font-medium">
                        Timezone
                    </Label>
                    <div className="relative">
                        <Globe className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5 z-10" />
                        <Select
                            value={formData.timezone}
                            onValueChange={(value) =>
                                setFormData((prev: EventFormData) => ({ ...prev, timezone: value }))
                            }
                        >
                            <SelectTrigger className="h-12 pl-12 border-gray-200">
                                <SelectValue placeholder="Select timezone" />
                            </SelectTrigger>
                            <SelectContent>
                                {[
                                    'America/New_York',
                                    'America/Chicago',
                                    'America/Denver',
                                    'America/Los_Angeles',
                                    'America/Anchorage',
                                    'Pacific/Honolulu',
                                    'Europe/London',
                                    'Europe/Paris',
                                    'Europe/Berlin',
                                    'Europe/Moscow',
                                    'Asia/Dubai',
                                    'Asia/Shanghai',
                                    'Asia/Tokyo',
                                    'Australia/Sydney',
                                    'Pacific/Auckland'
                                ].map((tz) => (
                                    <SelectItem key={tz} value={tz}>
                                        {tz.replace(/_/g, ' ')}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                </div>
            </div>

            {/* URLs */}
            <div className="space-y-2">
                <div className="relative">
                    <LinkIcon className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                    <URLInput
                        name="eventUrl"
                        value={formData.eventUrl}
                        onChange={(e) =>
                            setFormData((prev: EventFormData) => ({
                                ...prev,
                                [e.target.name]: e.target.value,
                            }))
                        }
                        label="Event URL"
                        required={false}
                    />
                </div>
            </div>

            {/* Description */}
            <div className="space-y-2">
                <Label htmlFor="description" className="text-sm font-medium">
                    Description (Optional)
                </Label>
                <Textarea
                    id="description"
                    value={formData.description}
                    onChange={(e) =>
                        setFormData((prev: EventFormData) => ({
                            ...prev,
                            description: e.target.value,
                        }))
                    }
                    placeholder="Tell people more about your event..."
                    className="h-32 resize-none border-gray-200 focus:border-purple-500 focus:ring-purple-500"
                />
            </div>

            {error && (
                <Alert variant="destructive" className="mt-4">
                    <AlertDescription>{error}</AlertDescription>
                </Alert>
            )}
        </div>
    );
};

export default EventDetails;