import { Loader2 } from "lucide-react";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { EventCreationResponse } from "@/types/api";
import { useAuth } from "@/components/AuthProvider";
import { CreateNewEvent } from "@/types/Strings";
import { getDefaultStartTime, calculateEndTime, convertToUTC } from "@/lib/time_utils";
import EventDetails from "@/components/EventDetails";
import { EventFormData } from "@/components/EventDetails";

const EventCreationPage = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState(false);

    if (!user || !user.username) {
        navigate("/");
    }

    const [formData, setFormData] = useState<EventFormData>({
        title: "",
        venue: "",
        startDateTime: "",
        endDateTime: "",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        eventUrl: "",
        description: "",
        userId: "",
    });

    useEffect(() => {
        const defaultStart = getDefaultStartTime();
        setFormData(prev => ({
            ...prev,
            startDateTime: defaultStart,
            endDateTime: calculateEndTime(defaultStart)
        }));
    }, []);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);

        try {
            const formDataToSend = new FormData();

            const formattedData = {
                ...formData,
                startDateTime: convertToUTC(formData.startDateTime, formData.timezone),
                endDateTime: formData.endDateTime
                    ? convertToUTC(formData.endDateTime, formData.timezone)
                    : '',
            };

            Object.entries(formattedData).forEach(([key, value]) => {
                if (value) formDataToSend.append(key, value);
            });

            if (user) {
                formDataToSend.set("userId", String(user.id));
            }

            const response = await fetch("/api/create-event", {
                method: "POST",
                body: formDataToSend,
            });

            if (!response.ok) {
                const data: EventCreationResponse = await response.json();
                throw new Error(data.message || "Failed to create event");
            }

            setSuccess(true);
            setTimeout(() => {
                navigate(`/${user?.username}`);
            }, 2000);
        } catch (err) {
            setError(err instanceof Error ? err.message : "Something went wrong");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white flex flex-col items-center p-4">
            <div className="w-full max-w-2xl py-8">
                <Card className="border-0 shadow-lg">
                    <CardHeader className="text-center space-y-2">
                        <CardTitle className="text-3xl font-bold bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
                            {CreateNewEvent}
                        </CardTitle>
                        <CardDescription className="text-gray-600 text-lg">
                            Share your next performance with your followers
                        </CardDescription>
                    </CardHeader>
                    <CardContent>
                        <form onSubmit={handleSubmit} className="space-y-6">
                            <EventDetails
                                formData={formData}
                                setFormData={setFormData}
                                error={error}
                            />

                            {success && (
                                <Alert className="mt-4 bg-green-50 text-green-700 border-green-200">
                                    <AlertDescription>
                                        Event created successfully! Redirecting to your profile...
                                        ✨
                                    </AlertDescription>
                                </Alert>
                            )}

                            <Button
                                type="submit"
                                className="w-full h-12 bg-gradient-to-r from-purple-600 to-blue-600 hover:from-purple-700 hover:to-blue-700 text-white transition-all duration-200 transform hover:scale-[1.02]"
                                disabled={
                                    isLoading ||
                                    !formData.title ||
                                    !formData.venue ||
                                    !formData.startDateTime ||
                                    !formData.timezone ||
                                    success
                                }
                            >
                                {isLoading ? (
                                    <div className="flex items-center justify-center space-x-2">
                                        <Loader2 className="w-5 h-5 animate-spin" />
                                        <span>Creating Event...</span>
                                    </div>
                                ) : (
                                    "Create Event"
                                )}
                            </Button>
                        </form>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default EventCreationPage;