import React, { useState, ChangeEvent, FocusEvent } from 'react';
import { AlertCircle, CheckCircle } from 'lucide-react';
import { Alert, AlertDescription } from '@/components/ui/alert';

interface URLInputProps {
    name: string;
    value: string;
    onChange: (e: { target: { name: string; value: string } }) => void;
    label: string;
    placeholder?: string;
    className?: string;
    required?: boolean;
}

const URLInput: React.FC<URLInputProps> = ({
    name,
    value,
    onChange,
    label,
    placeholder = 'Enter ticket link, fb page, wherever you want people to go...',
    className = '',
    required = false
}) => {
    const [error, setError] = useState('');
    const [isValid, setIsValid] = useState(false);
    const inputId = React.useId();

    const normalizeUrl = (url: string): string => {
        let normalizedUrl = url.trim();
        if (!normalizedUrl) return '';

        // If URL doesn't start with protocol, add https://
        if (!/^https?:\/\//i.test(normalizedUrl)) {
            normalizedUrl = 'https://' + normalizedUrl;
        }

        return normalizedUrl;
    };

    const validateUrl = (url: string): boolean => {
        // Handle empty input
        if (!url.trim()) {
            setError('');
            setIsValid(false);
            return true; // Allow empty value, just don't mark as valid
        }

        try {
            const urlObject = new URL(normalizeUrl(url));

            // Additional validation checks
            if (!urlObject.hostname.includes('.')) {
                throw new Error('Invalid domain name');
            }

            // Check for common TLDs or IP addresses
            const isValidDomain = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/i.test(urlObject.hostname) ||
                /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(urlObject.hostname);

            if (!isValidDomain) {
                throw new Error('Invalid domain name');
            }

            setError('');
            setIsValid(true);
            return true;

        } catch (err) {
            setError('Please enter a valid URL');
            setIsValid(false);
            return false;
        }
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;

        // Update form with raw value during typing
        onChange({
            target: {
                name,
                value: newValue
            }
        });

        // Just validate without normalizing
        validateUrl(newValue);
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        const rawValue = e.target.value;

        if (!rawValue.trim()) {
            return; // Don't process empty values
        }

        if (validateUrl(rawValue)) {
            // Only normalize and update if valid
            const normalizedUrl = normalizeUrl(rawValue);
            onChange({
                target: {
                    name,
                    value: normalizedUrl
                }
            });
        }
    };

    return (
        <div className="w-full space-y-2">
            <label
                htmlFor={inputId}
                className="block text-sm font-medium text-gray-700 mb-1"
            >
                {label}
                {required && <span className="text-red-500 ml-1">*</span>}
            </label>
            <div className="relative">
                <input
                    id={inputId}
                    name={name}
                    type="text"
                    value={value}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    placeholder={placeholder}
                    required={required}
                    aria-invalid={error ? 'true' : 'false'}
                    aria-describedby={error ? `${inputId}-error` : undefined}
                    className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${error ? 'border-red-500' : isValid ? 'border-green-500' : 'border-gray-300'
                        } ${className}`}
                />
                {error && (
                    <AlertCircle className="absolute right-3 top-2.5 h-5 w-5 text-red-500" />
                )}
                {isValid && !error && (
                    <CheckCircle className="absolute right-3 top-2.5 h-5 w-5 text-green-500" />
                )}
            </div>

            {error && (
                <Alert variant="destructive" className="py-2">
                    <AlertDescription
                        id={`${inputId}-error`}
                        className="text-sm text-red-500"
                    >
                        {error}
                    </AlertDescription>
                </Alert>
            )}
        </div>
    );
};

export default URLInput;