import React, { useEffect, useState } from 'react';
import { Music, ArrowRight, CalendarDays, Sparkles, Link as LinkIcon, Share2, ExternalLink, ChevronLeft, ChevronRight } from 'lucide-react';
import { useAuth } from '@/components/AuthProvider';
import { Avatar, AvatarImage } from '@/components/ui/avatar';
import { Header } from '@/components/Header';
import { useNavigate } from 'react-router-dom';
import Testimonials from '@/components/Testimonials';

const LandingPage = () => {
    const navigate = useNavigate()
    const { user, login } = useAuth();
    const handleGoToProfile = () => {
        if (user && user.username) {
            navigate(`/${user.username}`);
        } else if (user) {
            navigate(`/register-user`);
        }
        else {
            login(); // Fallback to the login function if user data is not available
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-purple-50 via-white to-purple-50">
            {/* Enhanced Navigation */}
            <Header />

            {/* Main Content */}
            <div className="max-w-6xl mx-auto px-6 pt-32 pb-20">
                {/* Enhanced Hero Section */}
                <div className="grid md:grid-cols-2 gap-12 items-center mb-24">
                    <div className="space-y-8">
                        <div className="inline-flex items-center space-x-2 bg-purple-100 px-4 py-2 rounded-full">
                            <Sparkles className="h-4 w-4 text-purple-600" />
                            <span className="text-sm text-purple-700">One link for all your gigs</span>
                        </div>
                        <h1 className="text-6xl font-extrabold text-gray-900 leading-tight tracking-tight">
                            Keep your fans
                            <span className="relative block">
                                {/* <span className="absolute -inset-1 w-full h-full bg-gradient-to-r from-purple-600 via-pink-500 to-purple-800 blur-lg opacity-30"></span> */}
                                <span className="relative block bg-gradient-to-r from-purple-600 via-pink-500 to-purple-800 bg-clip-text text-transparent">
                                    in the loop
                                </span>
                            </span>
                        </h1>
                        <p className="text-xl text-gray-600">
                            <b>Share one simple link.</b> Let your friends and fans know where to find you next. No more scattered event posts across different platforms.
                        </p>
                        {!user && (
                            <div className="flex items-center space-x-4">
                                <button onClick={login}
                                    className="bg-purple-600 text-white px-8 py-3 rounded-lg hover:bg-purple-700 transition-all transform hover:scale-105 shadow-lg hover:shadow-purple-200 inline-flex items-center animate-pulse">
                                    Sign Up
                                    <ArrowRight className="ml-2 h-5 w-5" />
                                </button>
                                <a href="#demo" className="text-purple-600 hover:text-purple-700 font-medium">
                                    See how it works →
                                </a>
                            </div>
                        )}
                        {user && user.username && (
                            <div className="flex items-center space-x-4">
                                <button onClick={handleGoToProfile}
                                    className="bg-purple-600 text-white px-8 py-3 rounded-lg hover:bg-purple-700 transition-all transform hover:scale-105 shadow-lg hover:shadow-purple-200 inline-flex items-center animate-pulse">
                                    Go to your profile
                                    <ArrowRight className="ml-2 h-5 w-5" />
                                </button>
                                <a href="#demo" className="text-purple-600 hover:text-purple-700 font-medium">
                                    See how it works →
                                </a>
                            </div>
                        ) || (user &&
                            <div className="flex items-center space-x-4">
                                <button onClick={handleGoToProfile}
                                    className="bg-purple-600 text-white px-8 py-3 rounded-lg hover:bg-purple-700 transition-all transform hover:scale-105 shadow-lg hover:shadow-purple-200 inline-flex items-center animate-pulse">
                                    Continue setting up
                                    <ArrowRight className="ml-2 h-5 w-5" />
                                </button>
                                <a href="#demo" className="text-purple-600 hover:text-purple-700 font-medium">
                                    See how it works →
                                </a>
                            </div>
                            )}
                    </div>

                    {/* Enhanced Preview Card */}
                    <div className="relative">
                        <div className="absolute inset-0 bg-gradient-to-r from-purple-200 to-pink-200 rounded-2xl transform rotate-3"></div>
                        <div className="relative bg-white rounded-2xl p-8 shadow-xl">
                            <div className="flex items-center space-x-4 mb-8">
                                <Avatar className="w-20 h-20 ring-4 ring-purple-100">
                                    <AvatarImage src={"https://upload.wikimedia.org/wikipedia/commons/9/9e/20200229_03_Alcest_%2849605436357%29_%28cropped%29.jpg"} />
                                </Avatar>
                                <div>
                                    <div className="text-xl font-bold">Sarah & The Soundwaves</div>
                                    <div className="text-purple-600">@sarahsoundwaves</div>
                                </div>
                            </div>

                            {/* Enhanced Events */}
                            <div className="space-y-4">
                                {[
                                    { title: "Live at Jazz Corner", venue: "The Jazz Corner", date: "This Friday, 9 PM" },
                                    { title: "Acoustic Sessions", venue: "The Cozy Cafe", date: "Next Thursday, 8 PM" },
                                ].map((event, i) => (
                                    <div key={i}
                                        className="group bg-white p-6 rounded-xl border border-gray-100 hover:border-purple-200 transition-all hover:shadow-lg">
                                        <div className="flex justify-between items-start">
                                            <div>
                                                <h3 className="font-bold text-lg group-hover:text-purple-600 transition-colors">
                                                    {event.title}
                                                </h3>
                                                <div className="text-gray-600 mt-1">{event.venue}</div>
                                            </div>
                                            <button className="text-gray-400 hover:text-purple-600 transition-colors">
                                                <ExternalLink className="h-5 w-5" />
                                            </button>
                                        </div>
                                        <div className="flex items-center text-sm text-purple-600 mt-4 font-medium">
                                            <CalendarDays className="h-4 w-4 mr-2" />
                                            {event.date}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <Testimonials />

                {/* Enhanced How it Works */}
                <div className="mt-24 text-center" id="demo">
                    <h2 className="text-3xl font-bold mb-16">Share your gigs in three easy steps</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
                        {[
                            { step: "1", icon: Music, text: "Create your page", description: "Sign up and get your personalized link" },
                            { step: "2", icon: CalendarDays, text: "Add your gigs", description: "List where and when you're performing next" },
                            { step: "3", icon: Share2, text: "Share your link", description: "Let your friends and fans know where to find your schedule" },
                        ].map((item) => (
                            <div key={item.step} className="relative group transform hover:scale-105">
                                <div className="absolute inset-0 bg-purple-100 rounded-2xl transform transition-transform group-hover:scale-105 -z-10"></div>
                                <div className="relative bg-white rounded-2xl p-8 shadow-sm group-hover:shadow-md transition-all">
                                    <div className="w-12 h-12 bg-purple-600 text-white rounded-xl flex items-center justify-center mx-auto mb-6">
                                        <p className='font-bold'>{item.step}</p>
                                    </div>
                                    <h3 className="font-bold text-xl mb-2">{item.text}</h3>
                                    <p className="text-gray-600">{item.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Enhanced Footer */}
            <footer className="border-t bg-white/80 backdrop-blur-sm">
                <div className="max-w-6xl mx-auto px-6 py-8 flex flex-col md:flex-row items-center justify-between">
                    <div className="flex items-center space-x-4 mb-4 md:mb-0">
                        <Music className="h-5 w-5 text-purple-600" />
                        <span className="font-medium">© 2025 allmygigs</span>
                    </div>
                    <div className="flex items-center space-x-6">
                        <a href="#" className="text-gray-600 hover:text-purple-600 transition-colors">Terms</a>
                        <a href="#" className="text-gray-600 hover:text-purple-600 transition-colors">Privacy</a>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default LandingPage;