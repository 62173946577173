import { toZonedTime, format } from 'date-fns-tz';
import { parseISO } from 'date-fns';
export const convertToUTC = (dateTimeStr: string, timezone: string): string => {
    if (!dateTimeStr) return '';

    // Create a date object from the local datetime string
    const date = new Date(dateTimeStr);

    // Format in target timezone
    return date.toISOString();
};

export const convertFromUTC = (utcDateString: string, timezone: string): string => {
    // Parse the UTC date string
    const date = parseISO(utcDateString);

    // Convert UTC to target timezone
    const zonedDate = toZonedTime(date, timezone);

    // Format the date in the desired format
    return format(zonedDate, "yyyy-MM-dd'T'HH:mm", { timeZone: timezone });
};
export const getDefaultStartTime = (): string => {
    const now = new Date();
    // Round to next 30 minutes
    now.setMinutes(Math.ceil(now.getMinutes() / 30) * 30);
    now.setSeconds(0);
    now.setMilliseconds(0);

    // Format for datetime-local input in local timezone
    return now.toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    }).replace(' ', 'T');
};

export const calculateEndTime = (startTimeStr: string): string => {
    if (!startTimeStr) return '';
    const startTime = new Date(startTimeStr);
    const endTime = new Date(startTime);
    endTime.setHours(23, 59, 59, 999); // Set to 11:59:59.999 PM

    // Format for datetime-local input in local timezone
    return endTime.toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    }).replace(' ', 'T');
};

export const formatEventTime = (dateStr: string) => {
    const date = new Date(dateStr);
    return date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
    });
};

export const formatEventDate = (dateStr: string) => {
    const date = new Date(dateStr);
    return {
        month: date.toLocaleString('default', { month: 'short' }),
        day: date.getDate(),
        weekday: date.toLocaleString('default', { weekday: 'long' })
    };
};