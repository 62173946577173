import { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { X, Clock, MapPinned, ArrowLeft, Sparkles, Home } from "lucide-react";
import { Event } from "@/types/api";
import { formatEventDate, formatEventTime } from "@/lib/time_utils";
import AddToCalendar from "@/components/AddToCalendar";
import EventLink from "@/components/EventLink";
import ProfileFooter from "@/components/ProfileFooter";

interface EventPageProps {
    isStandalone?: boolean;
}

// Component for the event page or modal.
const EventPage = ({ isStandalone = false }: EventPageProps) => {
    const { eventId } = useParams();
    const navigate = useNavigate();
    const [event, setEvent] = useState<Event | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    // Animation states
    const [isVisible, setIsVisible] = useState(false);
    const [isLeaving, setIsLeaving] = useState(false);

    useEffect(() => {
        const fetchEvent = async () => {
            try {
                setLoading(true);
                const response = await fetch(`/api/get-event/${eventId}`);

                if (!response.ok) {
                    throw new Error('Failed to fetch event');
                }

                const data = await response.json() as { event: Event };
                setEvent(data.event);
            } catch (err) {
                setError(err instanceof Error ? err.message : 'Failed to fetch event');
            } finally {
                setLoading(false);
                // Trigger entrance animation
                setTimeout(() => setIsVisible(true), 10);
            }
        };

        fetchEvent();

        // Add keyboard listener for Escape key
        const handleEscape = (e: KeyboardEvent) => {
            if (e.key === 'Escape' && !isStandalone) {
                handleClose();
            }
        };

        window.addEventListener('keydown', handleEscape);

        return () => {
            window.removeEventListener('keydown', handleEscape);
        };
    }, [eventId, isStandalone]);

    const handleClose = () => {
        // Trigger exit animation
        setIsLeaving(true);

        // Wait for animation to complete before navigating
        setTimeout(() => {
            // Go back to the previous page
            navigate(-1);
        }, 300); // Match this with the CSS animation duration
    };

    // Handle click outside the modal to close it
    const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.target === e.currentTarget && !isStandalone) {
            handleClose();
        }
    };

    // Format dates if event exists
    const formattedStartDate = event ? formatEventDate(event.start_datetime) : null;
    const formattedEndDate = event?.end_datetime ? formatEventDate(event.end_datetime) : null;
    const formattedStartTime = event ? formatEventTime(event.start_datetime) : null;
    const formattedEndTime = event?.end_datetime ? formatEventTime(event.end_datetime) : null;

    // Animation classes for modal
    const overlayClasses = `fixed inset-0 bg-black z-50 backdrop-blur-sm transition-all duration-300 ${isVisible && !isLeaving ? 'bg-opacity-50' : 'bg-opacity-0'
        } ${isLeaving ? 'bg-opacity-0' : ''}`;

    const modalClasses = `bg-white rounded-2xl shadow-2xl max-w-3xl w-full mx-auto my-8 overflow-hidden transition-all duration-300 transform ${isVisible && !isLeaving ? 'translate-y-0 opacity-100' : 'translate-y-8 opacity-0'
        } ${isLeaving ? 'translate-y-8 opacity-0' : ''}`;

    if (loading) {
        // Different loading state based on view mode
        if (isStandalone) {
            return (
                <div className="min-h-screen bg-gradient-to-b from-indigo-100 to-purple-50 p-8 flex justify-center items-start">
                    <div className="bg-white rounded-2xl p-6 shadow-2xl max-w-2xl w-full animate-pulse">
                        <div className="h-8 bg-gray-200 rounded mb-4"></div>
                        <div className="h-64 bg-gray-200 rounded mb-4"></div>
                        <div className="h-4 bg-gray-200 rounded mb-2"></div>
                        <div className="h-4 bg-gray-200 rounded mb-2"></div>
                        <div className="h-4 bg-gray-200 rounded"></div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={overlayClasses}>
                    <div className="flex items-center justify-center min-h-screen p-4">
                        <div className={`${modalClasses} p-6 animate-pulse`}>
                            <div className="h-8 bg-gray-200 rounded mb-4"></div>
                            <div className="h-64 bg-gray-200 rounded mb-4"></div>
                            <div className="h-4 bg-gray-200 rounded mb-2"></div>
                            <div className="h-4 bg-gray-200 rounded mb-2"></div>
                            <div className="h-4 bg-gray-200 rounded"></div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    if (error) {
        // Different error state based on view mode
        if (isStandalone) {
            return (
                <div className="min-h-screen bg-gradient-to-b from-indigo-100 to-purple-50 p-8 flex justify-center items-start">
                    <div className="bg-white rounded-2xl p-6 shadow-2xl max-w-2xl w-full">
                        <div className="text-red-500 text-center">
                            <h2 className="text-2xl font-bold mb-2">Error</h2>
                            <p>{error}</p>
                            <button
                                onClick={() => navigate(-1)}
                                className="mt-4 px-4 py-2 bg-red-100 text-red-700 rounded-lg hover:bg-red-200 transition-colors"
                            >
                                Go Back
                            </button>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={overlayClasses} onClick={handleOverlayClick}>
                    <div className="flex items-center justify-center min-h-screen p-4">
                        <div className={modalClasses}>
                            <div className="p-6 text-red-500 text-center">
                                <h2 className="text-2xl font-bold mb-2">Error</h2>
                                <p>{error}</p>
                                <button
                                    onClick={handleClose}
                                    className="mt-4 px-4 py-2 bg-red-100 text-red-700 rounded-lg hover:bg-red-200 transition-colors"
                                >
                                    Go Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    if (!event) {
        // Different not found state based on view mode
        if (isStandalone) {
            return (
                <div className="min-h-screen bg-gradient-to-b from-indigo-100 to-purple-50 p-8 flex justify-center items-start">
                    <div className="bg-white rounded-2xl p-6 shadow-2xl max-w-2xl w-full">
                        <div className="text-center">
                            <h2 className="text-2xl font-bold mb-2">Event Not Found</h2>
                            <button
                                onClick={() => navigate('/')}
                                className="mt-4 px-4 py-2 bg-indigo-100 text-indigo-700 rounded-lg hover:bg-indigo-200 transition-colors"
                            >
                                Go to Home
                            </button>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={overlayClasses} onClick={handleOverlayClick}>
                    <div className="flex items-center justify-center min-h-screen p-4">
                        <div className={modalClasses}>
                            <div className="p-6 text-center">
                                <h2 className="text-2xl font-bold mb-2">Event Not Found</h2>
                                <button
                                    onClick={handleClose}
                                    className="mt-4 px-4 py-2 bg-indigo-100 text-indigo-700 rounded-lg hover:bg-indigo-200 transition-colors"
                                >
                                    Go Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    // Render different UI based on whether this is a standalone page or modal
    if (isStandalone) {
        return (
            <div className="min-h-screen bg-gradient-to-b from-purple-500 to-indigo-600 py-8 px-4">
                <div className="max-w-3xl mx-auto">
                    <div className="bg-white rounded-2xl shadow-xl overflow-hidden mb-8">
                        {/* Navigation for standalone view - Now with title */}
                        <div className="p-4 bg-indigo-600 text-white">
                            {/* Title moved to the top section */}
                            <h1 className="text-2xl md:text-3xl font-bold mb-4">{event.title}</h1>

                            {/* Action buttons - More prominent Add to Calendar */}
                            <div className="flex flex-wrap gap-3 items-center">

                                <AddToCalendar event={event} showExpandedCTA={true} />
                                {event.event_url && (
                                    <EventLink url={event.event_url} />
                                )}
                            </div>
                        </div>

                        {/* The rest of the event content */}
                        {event.cover_image_url && (
                            <div className="relative w-full h-64 md:h-80">
                                <img
                                    src={event.cover_image_url}
                                    alt={event.title}
                                    className="w-full h-full object-cover"
                                />
                                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>

                                {event.is_verified && (
                                    <div className="absolute top-4 right-4 z-20 flex items-center gap-1 bg-gradient-to-r from-emerald-400 to-teal-400 text-white px-3 py-1 rounded-full text-sm font-medium">
                                        <Sparkles className="h-4 w-4" />
                                        Verified
                                    </div>
                                )}
                            </div>
                        )}

                        {/* Content */}
                        <div className="p-6 md:p-8">
                            {/* Title removed from here since it's now in the top section */}

                            <div className="flex flex-wrap gap-4 mb-6">
                                {/* Date badge */}
                                <div className="bg-gradient-to-br from-fuchsia-500 to-violet-600 rounded-xl px-4 py-2 text-white shadow-lg shadow-fuchsia-200">
                                    <div className="text-2xl font-bold flex items-center justify-center gap-1.5">
                                        <span className="text-fuchsia-100">{formattedStartDate?.month}</span>
                                        <span>{formattedStartDate?.day}</span>
                                    </div>
                                    <div className="h-px bg-white/20 my-1.5 w-full"></div>
                                    <div className="text-sm text-fuchsia-100">
                                        {formattedStartDate?.weekday}
                                    </div>
                                </div>

                                {/* Time */}
                                <div className="flex items-center bg-indigo-50 text-indigo-800 rounded-lg px-4 py-2">
                                    <Clock className="h-5 w-5 mr-2 text-indigo-500" />
                                    <span>
                                        {formattedStartTime}
                                        {formattedEndTime && ` - ${formattedEndTime}`}
                                    </span>
                                </div>

                                {/* Location */}
                                <div className="flex items-center bg-indigo-50 text-indigo-800 rounded-lg px-4 py-2">
                                    <MapPinned className="h-5 w-5 mr-2 text-indigo-500" />
                                    <span>{event.venue}</span>
                                </div>
                            </div>

                            {/* Description */}
                            {event.description && (
                                <div className="mt-6">
                                    <h2 className="text-xl font-semibold text-gray-800 mb-3">About this event</h2>
                                    <div className="prose prose-indigo max-w-none text-gray-700">
                                        <p>{event.description}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <ProfileFooter />
            </div>
        );
    } else {
        return (
            <div
                className={overlayClasses}
                onClick={handleOverlayClick}
            >
                <div className="flex items-center justify-center min-h-screen p-4">
                    <div className={modalClasses}>
                        {/* Close button */}
                        <button
                            onClick={handleClose}
                            className="absolute top-4 right-4 z-50 bg-white/80 hover:bg-white text-gray-700 hover:text-red-500 rounded-full p-2 shadow-md transition-colors"
                            aria-label="Close"
                        >
                            <X className="h-6 w-6" />
                        </button>

                        {/* Cover image */}
                        {event.cover_image_url && (
                            <div className="relative w-full h-64 md:h-80">
                                <img
                                    src={event.cover_image_url}
                                    alt={event.title}
                                    className="w-full h-full object-cover"
                                />
                                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>

                                {event.is_verified && (
                                    <div className="absolute top-4 right-16 z-20 flex items-center gap-1 bg-gradient-to-r from-emerald-400 to-teal-400 text-white px-3 py-1 rounded-full text-sm font-medium">
                                        <Sparkles className="h-4 w-4" />
                                        Verified
                                    </div>
                                )}
                            </div>
                        )}

                        {/* Content */}
                        <div className="p-6 md:p-8">
                            <h1 className="text-3xl font-bold text-gray-900 mb-4">{event.title}</h1>

                            <div className="flex flex-wrap gap-4 mb-6">
                                {/* Date badge */}
                                <div className="bg-gradient-to-br from-fuchsia-500 to-violet-600 rounded-xl px-4 py-2 text-white shadow-lg shadow-fuchsia-200">
                                    <div className="text-2xl font-bold flex items-center justify-center gap-1.5">
                                        <span className="text-fuchsia-100">{formattedStartDate?.month}</span>
                                        <span>{formattedStartDate?.day}</span>
                                    </div>
                                    <div className="h-px bg-white/20 my-1.5 w-full"></div>
                                    <div className="text-sm text-fuchsia-100">
                                        {formattedStartDate?.weekday}
                                    </div>
                                </div>

                                {/* Time */}
                                <div className="flex items-center bg-indigo-50 text-indigo-800 rounded-lg px-4 py-2">
                                    <Clock className="h-5 w-5 mr-2 text-indigo-500" />
                                    <span>
                                        {formattedStartTime}
                                        {formattedEndTime && ` - ${formattedEndTime}`}
                                    </span>
                                </div>

                                {/* Location */}
                                <div className="flex items-center bg-indigo-50 text-indigo-800 rounded-lg px-4 py-2">
                                    <MapPinned className="h-5 w-5 mr-2 text-indigo-500" />
                                    <span>{event.venue}</span>
                                </div>
                            </div>

                            {/* Action buttons */}
                            <div className="flex flex-wrap gap-3 mb-6">
                                <AddToCalendar event={event} showExpandedCTA={false} />
                                {event.event_url && <EventLink url={event.event_url} />}
                            </div>

                            {/* Description */}
                            {event.description && (
                                <div className="mt-6">
                                    <h2 className="text-xl font-semibold text-gray-800 mb-3">About This Event</h2>
                                    <div className="prose prose-indigo max-w-none text-gray-700">
                                        <p>{event.description}</p>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default EventPage;