import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { UserPage } from './pages/UserPage';
import LandingPage from './pages/LandingPage';
import RegistrationPage from './pages/RegistrationPage';
import { AuthProvider, useAuth } from './components/AuthProvider';
import EventCreationPage from './pages/EventCreationPage';
import EditUserPage from './pages/EditUserPage';
import EventEditPage from './pages/EventEditPage';
import EventPage from './pages/EventPage'; 

const queryClient = new QueryClient();

// Protected route that requires authentication
const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/" replace />;
  }

  return children;
};

// Route that redirects authenticated users away (e.g., login page)
const PublicOnlyRoute = ({ children }: { children: JSX.Element }) => {
  const { user } = useAuth();

  if (user?.username) {
    return <Navigate to={`/${user.username}`} replace />;
  }

  return children;
};

// Route that requires authentication but no username (registration flow)
const RegistrationRoute = ({ children }: { children: JSX.Element }) => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/" replace />;
  }

  // If user has username, they're already registered
  if (user.username) {
    return <Navigate to={`/${user.username}`} replace />;
  }

  return children;
};

// Component that manages the routing with modal support
const AppRoutes = () => {
  const location = useLocation();
  const state = location.state as { backgroundLocation?: Location };

  // Use the state's backgroundLocation as the location for the main routes if it exists
  const backgroundLocation = state?.backgroundLocation;

  return (
    <div className="min-h-screen">
      <main>
        {/* Main routes */}
        <Routes location={backgroundLocation || location}>
          {/* Public routes */}
          <Route path="/" element={<LandingPage />} />

          {/* Registration flow */}
          <Route
            path="/register-user"
            element={
              <RegistrationRoute>
                <RegistrationPage />
              </RegistrationRoute>
            }
          />

          {/* Protected routes */}
          <Route
            path="/create-event"
            element={
              <ProtectedRoute>
                <EventCreationPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/events/:eventId/edit"
            element={
              <ProtectedRoute>
                <EventEditPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/edit-user"
            element={
              <ProtectedRoute>
                <EditUserPage />
              </ProtectedRoute>
            }
          />

          {/* Event page - direct access (non-modal) */}
          <Route path="/event/:eventId" element={<EventPage isStandalone={true} />} />

          {/* Public profile pages - no protection needed */}
          <Route path="/:username" element={<UserPage />} />

          {/* Catch all route */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>

        {/* Modal routes - only render when we have a background location */}
        {backgroundLocation && (
          <Routes>
            <Route path="/event/:eventId" element={<EventPage isStandalone={false} />} />
          </Routes>
        )}
      </main>
    </div>
  );
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <GoogleOAuthProvider clientId='765620806017-p6465k0a8o8ff611b9a87g57o0ue64fn.apps.googleusercontent.com'>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </GoogleOAuthProvider>
      </Router>
    </QueryClientProvider>
  );
}

export default App;